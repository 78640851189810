<template>
    <CountdownCircleComponent />
</template>

<script>
import CountdownCircleComponent from "@/components/CountdownCircleComponent"

export default {
    name: 'CountdownCirclePage',
    components: {
        CountdownCircleComponent,
    },
    data() {
        return {
        }
    },
    computed: {
        counter() {
            return this.$store.state.counter.counter
        },
        topics() {
            return this.$store.state.topics.all
        },
        filteredTopics() {
            if (this.topics.length > 0) {
                return this.topics.filter(topic => topic.seconds > 0 || topic.minutes > 0);
            }
            return this.topics
        },
    },
};
</script>
