<template>
  <div class="edit-upload">
    <h2>Edit Topic</h2>
    <form @submit.prevent="submitForm" class="edit-form">
      <label for="editTopicName" class="edit-label">Name:</label>
      <input class="edit-input" type="text" id="editTopicName" placeholder="Update Name" v-model="topicName" required>
      <label for="editMinutes" class="edit-label">Minutes:</label>
      <input class="edit-input" type="number" id="editMinutes" v-model.number="minutes" required>
      <label for="editSeconds" class="edit-label">Seconds:</label>
      <input class="edit-input" type="number" id="editSeconds" v-model.number="seconds" required>
      <button type="submit" class="edit-btn">Update</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UpdateTopic',
  props: ['topic'],
  data() {
    return {
      topicName: '',
      minutes: 0,
      seconds: 0,
      data: [],
    };
  },
  methods: {
    async submitForm() {
      const formData = {
        topic: this.topicName,
        minutes: this.minutes,
        seconds: this.seconds
      };
      try {
        await axios.put(`http://localhost/api/topics/${this.topic.id}`, formData);
        this.$emit('topic-updated', formData);
        this.data.push(formData)
      } catch (error) {
        console.error('Error updating topic:', error);
      }
    }
  },
  created() {
    this.topicName = this.topic.topic;
    this.minutes = this.topic.minutes;
    this.seconds = this.topic.seconds;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.edit-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: .5rem;
}

.edit-label {
  color: $text-color;
}

.edit-input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: $text-color;
  background-color: $bubble-color;
  border: 1px solid $bubble-color;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.edit-input:focus {
  border-color: $color-light_blue;
  outline: 0;
  box-shadow: 0 0 0 0.2rem $color-blue;
}

.edit-btn {
  border: none;
  color: $color-blue;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  background: $bubble-color;
  margin-right: .5rem;
  width: 100%;
}

.edit-btn:hover {
  color: $color-light_blue;
  background-color: $bubble-two-color;
}
</style>