<template>
    <div>{{ tokens }}</div>
</template>
<script>
import axios from 'axios';

export default {
    data() {
        return {
            token: null,
        }
    },
    computed: {
        tokens() {
            return this.$store.state.token
        },
    },
    async mounted() {
        const hash = window.location.hash;
        if (hash.length > 0 && hash.includes("#")) {
            this.token = hash.replace('#', '')
        }
        try {
            await axios.post(`http://localhost/api/twitch/token?${this.token}`,)
            window.close()
        } catch (error) {
            console.log(error)
        }
    },
}
</script>
