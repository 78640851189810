<template>
    <div class="backoffice">
        <ul class="tabs">
            <li><router-link :to="{ name: 'Backoffice' }">Dashboard</router-link></li>
            <li><router-link :to="{ name: 'BackofficeTopics' }">Topics</router-link></li>
            <li><router-link :to="{ name: 'BackofficeSettings' }">Settings</router-link></li>
            <li><router-link :to="{ name: 'Home' }">Exit</router-link></li>
        </ul>

        <router-view class="backoffice_view"></router-view>
    </div>

</template>

<script>
export default {
    name: 'BackofficePage',
    components: {
    },
    data() {
        return {
        }
    },
    mounted() {
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.backoffice {
    width: 100%;
    height: 100vh;
    background: $bubble-two-color;
    border-radius: 20px;
    color: $text-color;
    display: flex;
    flex-direction: column;

    .tabs {
        grid-area: tabs;
        display: flex;
        margin: 0;
        list-style: none;
        flex-shrink: 0;

        li {
            flex-basis: 0;
            flex-grow: 1;
            text-align: center;

            a {
                text-decoration: none;
                color: $text-color;
                padding: 1rem;
                display: block;

                &:hover {
                    background-color: rgba(white, .1);
                }

                &.router-link-exact-active {
                    color: white;
                    background-color: rgba(white, .15);
                }
            }
        }
    }

    .backoffice_view {
        flex-grow: 1;
        overflow: auto;
    }
}
</style>
