<template>
    <div class="topics-list-container">
        <div v-if="topics.length > 0" class="list-topic">
            <ul>
                <li v-for="topic in topics" :key="topic.id">
                    <p>Name: <strong>{{ topic.topic }}</strong></p>
                    <br>
                    <p>Time: <strong>{{ doubleDigit(topic.minutes) }}:{{ doubleDigit(topic.seconds) }}</strong></p>
                    <br>
                    <div class="buttons">
                        <button class="topics-btn btn-edit" @click.prevent="editTopic(topic)">Edit</button>
                        <button class="topics-btn btn-delete" @click.prevent="deleteTopic(topic.id)">Delete</button>
                    </div>
                </li>
            </ul>
        </div>

        <div class="popup-background" v-if="editingTopic">
            <div class="popup">
                <UpdateTopicComponent :topic="editingTopic" @topic-updated="handleTopicUpdated" />
                <button @click="closePopup" class="btn-close">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
import UpdateTopicComponent from '@/components/UpdateTopicComponent.vue'

import axios from 'axios';
import { socket } from '@/socket';

export default {
    name: 'TopicsList',
    components: {
        UpdateTopicComponent,
    },
    data() {
        return {
            editingTopic: null,
            popupVisible: false,
        }
    },
    methods: {
        editTopic(topic) {
            this.editingTopic = topic;
            this.popupVisible = true;
        },
        doubleDigit(number) {
            let nr = new String(number)
            if (nr.length == 1) {
                return '0' + nr
            }
            return nr
        },
        async deleteTopic(topicId) {
            try {
                await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/topics/${topicId}`);
            } catch (error) {
                console.error('Error deleting topic:', error);
            }
        },
        async handleTopicUpdated() {
            this.editingTopic = null;
            this.popupVisible = false;
        },
        closePopup() {
            this.editingTopic = null;
            this.popupVisible = false;
        },
    },
    computed: {
        token() {
            return this.$store.state.token
        },
        topics() {
            return this.$store.state.topics.all
        },
    },
    mounted() {
        this.$store.dispatch('topics/fetchAll')
        socket.on("topic-created", (topic) => {
            this.topics.push(topic)
        });
        socket.on("topic-updated", (topic) => {
            const index = this.topics.findIndex(({ id }) => id == topic.id)
            this.topics.splice(index, 1, topic)
        });
    },
    unmounted() {
        socket.off("topic-created")
        socket.off("topic-updated")
    },

}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.topics-list-container {
    grid-area: list;
    width: 100%;
    border-radius: 10px;
}

ul {
    list-style-type: none;
}

.buttons {
    transition: background-color 0.3s;
    text-align: center;
    color: $text-color;
    text-decoration: none;
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 1rem;
}

.topics-btn {
    border: none;
    padding: 0.5rem 1rem;
    font-size: 16px;
    border-radius: 5px;
    width: auto;
    cursor: pointer;
}


.btn-edit {
    background-color: $bubble-two-color;
    color: $text-color;
}

.btn-edit:hover {
    background-color: $bubble-two-color_light;
    color: $text-color_light;
}

.btn-delete {
    color: $color-red;
    background-color: $bubble-two-color;
}

.btn-delete:hover {
    background-color: $bubble-two-color_light;
    color: $color-light_red;
}


.popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $popup-background;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background-color: $bubble-two-color;
    padding: 2rem;
    border-radius: 10px;
}

.popup .btn-close {
    margin-top: 10px;
}

.btn-close {
    border: none;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: $bubble-color;
    margin-right: .5rem;
    color: $color-red;
    width: 100%;
}

.btn-close:hover {
    color: $color-light_red;
    background-color: $bubble-two-color;
}
</style>
