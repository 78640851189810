<template>
    <div class="message-upload">
        <h2>Add Message</h2>
        <br>
        <form @submit.prevent="submitMessage" class="message-form">
            <label for="message" class="message-label">Write your message:</label>
            <br>
            <br>
            <textarea ref="fileInput" @change="handleMessageInserted" class="message-input" type="text" id="message"
                v-model="message" placeholder="Write here...">
            </textarea>
            <br>
            <button type="submit" class="message-btn">Submit</button>
            <br>
            <br>
            <div>
                <p>Message Applied</p>
                <div class="message-applied">
                    <p v-if="messages">{{ messages.message }}</p>
                </div>
                <button type="submit" class="message-btn-delete" @click.prevent="deleteMessage">Remove</button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from 'axios'
import { socket } from '@/socket';

export default {
    name: 'MessageComponent',
    data() {
        return {
            message: '',
        }
    },
    methods: {
        handleMessageInserted(event) {
            this.message = event.target.value;
        },
        async submitMessage() {
            const messageData = {
                message: this.message,
            };
            try {
                await axios.post(`${process.env.VUE_APP_BACKEND_URL}/insertMessages`, messageData);
                this.$emit('message-inserted', messageData);
                this.message = '';
                this.$refs.fileInput.value = '';
            } catch (error) {
                console.error(error);
            }
        },
        async deleteMessage() {
            try {
                await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/removeMessage`);
                this.$store.dispatch("messages/fetch")
            } catch (error) {
                console.error('Error deleting topic:', error);
            }
        },
    },
    computed: {
        messages() {
            return this.$store.state.messages.messages
        }
    },
    mounted() {
        this.$store.dispatch("messages/fetch")

        socket.on("message-inserted", (insertedMessage) => {
            this.messages = insertedMessage
            this.$store.dispatch("messages/fetch")
        });
        socket.on("message-removed", () => {
            this.messages = null
            this.$store.dispatch("messages/fetch")
        });
    },
    unmounted() {
        socket.off("message-inserted")
        socket.off("message-removed")
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.message-upload {
    background: $bubble-color;
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    position: relative;
}

.message-label {
    color: $text-color;
}

.message-input {
    padding: 0.375rem 0.75rem;
    color: $text-color;
    background-color: $bubble-two-color;
    border: 1px solid $bubble-two-color;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

textarea {
    width: 100%;
    height: 30em;
}

.message-input:focus {
    border-color: $color-light_blue;
    outline: 0;
    box-shadow: 0 0 0 0.2rem $color-blue;
}

.message-btn {
    border: none;
    color: $color-blue;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: $bubble-two-color;
    width: 100%;
    width: -webkit-fill-available;
}

.message-btn:hover {
    color: $color-light_blue;
    background: $bubble-color;
}

.message-applied {
    background-color: $bubble-two-color;
    padding: 1rem;
    border-radius: 5px;
    margin: 1rem 0;
}

.message-btn-delete {
    border: none;
    color: $color-red;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    background-color: $bubble-two-color;
    cursor: pointer;
    width: -webkit-fill-available;
    font-size: 16px;
    border-radius: 5px;
    display: inline-block;
    transition: background-color 0.3s;
}

.message-btn-delete:hover {
    color: $color-light_red;
    background: $bubble-color;
}
</style>
