<template>
    <div class="timestamp" :class="cssClasses">
        <a href="#" @click.prevent="openFS" class="fs">
            <svg viewBox="0 0 24 24">
                <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 
                    7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
            </svg>
            <svg viewBox="0 0 24 24">
                <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 
                    11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z" />
            </svg>
        </a>

        <LogoComponent v-if="!message && logo" class="logo" />

        <CounterPage v-if="!message && counter !== false" class="timer" />

        <div class="top-info">
            <div class="message-container" v-if="message">
                <p class="message">{{ message.message }}</p>
            </div>
        </div>

        <div class="topics">
            <TopicsComponent class="list" />
            <CountdownCirclePage class="timer" />
        </div>

        <TotalProgress class="progress" />

        <div class="twitch">
            <TwitchFollowersList class="followers" :showButton="showButton" />
            <TwitchMessages class="twitchMessages" :showButton="showButton" />
        </div>

    </div>
</template>

<script>
import CountdownCirclePage from './CountdownCirclePage.vue';
import CounterPage from './CounterPage.vue';
import LogoComponent from '@/components/LogoComponent.vue'
import TopicsComponent from '@/components/TopicsComponent.vue';
import TotalProgress from '@/components/TotalProgress';
import TwitchFollowersList from '@/components/TwitchFollowersList.vue'
import TwitchMessages from '@/components/TwitchMessages.vue'

export default {
    name: 'TimestampPage',
    components: {
        LogoComponent,
        CounterPage,
        CountdownCirclePage,
        TopicsComponent,
        TotalProgress,
        TwitchFollowersList,
        TwitchMessages,
    },
    data() {
        return {
            logo: true,
            showButton: false,
            state: "normal",
        }
    },
    methods: {
        openFS() {
            if (!document.fullscreenElement) {
                document.body.requestFullscreen();
                document.body.setAttribute("fullscreen", "");
            } else {
                document.exitFullscreen();
                document.body.removeAttribute("fullscreen");
            }
        }
    },

    computed: {
        counter() {
            return this.$store.state.counter.counter
        },
        cssClasses() {
            if (this.orientation == null) {
                return {
                    normal: this.state == "normal",
                    vertical: this.state == "vertical",
                }
            }
            return {
                normal: this.orientation.value == "normal",
                vertical: this.orientation.value == "vertical",
            }
        },
        message() {
            return this.$store.state.messages.message
        },
        orientation() {
            return this.$store.getters['settings/name']('orientation')
        },
        token() {
            return this.$store.state.token
        },
    },

    mounted() {
        if (!this.counter || !this.logo) {
            this.$store.dispatch("messages/fetch")
        }
        this.$store.dispatch("getTwitchToken")
        this.$store.dispatch("settings/fetch", "orientation")
    },
}
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import "@/assets/_variables.scss";

.fs {
    position: absolute;
    z-index: 1000;
    width: calc(var(--unit) * 2);
    height: calc(var(--unit) * 2);

    top: var(--unit);
    right: var(--unit);
    transition: transform .3s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;

    svg {
        fill: white;
    }

    svg:nth-child(2) {
        display: none;
    }

    [fullscreen] & svg:nth-child(1) {
        display: none;
    }

    [fullscreen] & svg:nth-child(2) {
        display: inline-block;
    }
}

.timestamp {
    --unit: 1vw;
    --height: calc(#{math.div(100 * 9, 16)} * var(--unit));
    --width: calc(100 * var(--unit));
    width: var(--width);
    height: var(--height);

    @media screen and (min-aspect-ratio: 16/9) {
        --unit: #{math.div(16, 9)}vh;
    }

    display: grid;
    overflow: hidden;
    background: $background;
    position: relative;

    // SIZING
    padding: calc(var(--unit) * 1) calc(var(--unit) * 3);
    margin: auto;

    &.vertical {
        --unit: #{math.div(16, 9)}vw;
        --width: calc(#{math.div(100 * 9, 16)} * var(--unit));
        --height: calc(100 * var(--unit));

        @media screen and (min-aspect-ratio: 9/16) {
            --unit: 1vh;
        }

        grid-template-areas: "logo"
        "timer"
        "topics"
        "progress"
        ;
        grid-template-rows: 4fr 2fr 10fr 1fr;

        .twitch {
            display: none;
        }

        .topics {
            grid-template-columns: 1fr;
            grid-template-rows: 2fr 1fr;
        }
    }

    &.normal {
        grid-template-areas:
            "logo timer"
            "topics topics"
            "progress progress"
        ;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 3fr 6fr 1fr;

        .twitch {
            display: none;
        }
    }

    >.timer {
        grid-area: timer;
        margin: auto;
    }

    .logo {
        grid-area: logo;
        max-width: 100%;
        margin: auto;
    }

    .topics {
        grid-area: topics;
        display: grid;
        background-color: #1b1b21;
        border-radius: var(--unit);
        padding: var(--unit);
        height: 100%;
        overflow: hidden;
        grid-template-columns: 2fr 1fr;

        .timer {
            margin: auto;
        }

        .list {
            width: 100%;
            margin: auto;
        }
    }

    >.progress {
        grid-area: progress;
    }
}

.timestamp-router:visited {
    color: $text-color;
    text-decoration: none;
}

.timestamp-router:link {
    color: $text-color;
    text-decoration: none;
}

.message-container {
    color: $text-color_light;
    background-color: $bubble-two-color;
    padding: 1rem;
    margin: 1rem 0;
    font-size: 30px;
    border-radius: 10px;
    width: 100%;
    text-align: center;
}

.top-info {
    display: flex;
    justify-content: space-between;
    gap: 4em;
    padding: 0 2rem;
    align-items: center;
}

.container-topics {
    background: $bubble-two-color;
    border-radius: 15px;
    padding: 3%;
    display: flex;
    align-items: center;
}

.twitch {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin: 2rem 0;
}
</style>
