<template>
    <div class="dashboard">
        <CounterButtonsPage class="counter" />
        <UploadLogoComponent class="logo" />
        <MessagesComponent class="message" />

        <div style="display: none">{{ token }}</div>
        <div v-if="!token">
            <a :href="authURL" @click.prevent="openOauth">click</a>
        </div>
        <TwitchFollowersList class="followers" :showButton="showButton" />
        <TwitchMessages class="twitchMessages" :showButton="showButton" />
    </div>
</template>

<script>
import UploadLogoComponent from '@/components/UploadLogoComponent.vue';
import MessagesComponent from '@/components/MessagesComponent.vue'
import CounterButtonsPage from '@/views/CounterButtonsPage.vue'
import TwitchFollowersList from '@/components/TwitchFollowersList.vue'
import TwitchMessages from '@/components/TwitchMessages.vue'
import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL;

export default {
    name: "BackofficeDashboard",
    components: {
        UploadLogoComponent,
        CounterButtonsPage,
        MessagesComponent,
        TwitchFollowersList,
        TwitchMessages,
    },
    data() {
        return {
            showButton: true,
        }
    },
    methods: {
        openOauth() {
            let url = new URL(this.authURL)
            url.searchParams.set("client_id", process.env.VUE_APP_TWITCH_CLIENT_ID)
            url.searchParams.set("redirect_uri", process.env.VUE_APP_TWITCH_REDIRECT_URL)
            url.searchParams.set("response_type", "token")
            url.searchParams.set("scope", "moderator:read:followers")

            const authWindow = window.open(url, "twitch", "width=600, height=600")
            authWindow.onunload = () => {
                setTimeout(async () => {
                    if (authWindow.closed) {
                        await axios.get(`${API_URL}twitch/token`)
                    }
                }, 200)
            }
        },
        async getSubscriptions() {
            try {
                const result = await axios.get('http://localhost/api/twitch-subscribers')
                console.log(result)
            } catch (error) {
                console.log(error)
            }
        },
    },
    computed: {
        authURL() {
            return process.env.VUE_APP_TWITCH_AUTH_URL
        },
        token() {
            return this.$store.state.token
        },
    },
    mounted() {
        this.$store.dispatch("getTwitchToken")
        this.getSubscriptions()
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.dashboard {
    padding: 2rem;
    background: $bubble-two-color;
    color: $text-color;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    gap: 1.5rem;
    grid-template-areas:
        'logo counter'
        'twiFollowers message'
        'twiMessages message';

    .counter {
        grid-area: counter;
    }

    .tabs {
        grid-area: tabs;
        display: flex;
        margin: 0;
        list-style: none;

        li {
            flex-basis: 0;
            flex-grow: 1;
            text-align: center;

            a {
                text-decoration: none;
                color: $text-color;

                &.router-link-exact-active {
                    color: white;
                }
            }
        }
    }

    .backoffice-router:visited {
        color: $text-color;
        text-decoration: none;
    }

    .backoffice-router:link {
        color: $text-color;
        text-decoration: none;
    }

    .logo {
        grid-area: logo;
        width: auto;
    }

    .message {
        grid-area: message;
        width: auto;
    }

    .topics {
        grid-area: topics;
    }


    .followers {
        grid-area: twiFollowers;
    }

    .twitchMessages {
        grid-area: twiMessages;
        background: $bubble-color;
        width: 100%;
        padding: 1rem;
        border-radius: 10px;
    }

}
</style>
