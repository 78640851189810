<template>
    <div class="followers-list-container">
        <h2>Followers List</h2>
        <ul v-if="listVisible">
            <li v-for="follower in followers" :key="follower.user_id">
                <p>Name: <strong>{{ follower.user_name }}</strong></p>
            </li>
        </ul>
        <br>
        <br>
        <div v-if="showButton">
            <button class="btn-twitchFollowers" @click="toggleListVisibility">{{ listVisible ? 'Hide' : 'Show' }} List</button>
        </div>
    </div>
</template>
<script>
import { socket } from '@/socket';
export default {
    props: {
        showButton: {
            required: true
        },
    },
    methods: {
        toggleListVisibility() {
            this.$store.dispatch("toggleListVisibility")
        },
        getFollowers() {
            if (this.user == null) return
            if (!this.listVisible) return
            this.$store.dispatch("getFollowers")
        },
    },
    computed: {
        token() {
            return this.$store.state.token
        },
        user() {
            return this.$store.state.user
        },
        followers() {
            return this.$store.state.follower
        },
        listVisible() {
            return this.$store.state.listVisible
        },
    },
    watch: {
        user: {
            immediate: true,
            handler() {
                if (this.user == null) return
                this.getFollowers()
            }
        },
        listVisible: {
            immediate: true,
            handler() {
                if (this.listVisible) {
                    this.getFollowers()
                }
            }
        },
    },
    mounted() {
        this.$store.dispatch("getTwitchToken")
        socket.on("twitchfollowers-inserted", () => {
            this.$store.dispatch("getFollowersSettings")
        });
        socket.on("twitchSettings-inserted", () => {
            this.$store.dispatch("fetchTwitchSettings")
        });

    },
    unmounted() {
        socket.off("twitchfollowers-inserted")
        socket.off("twitchSettings-inserted")
    },
}
</script>
<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.followers-list-container {
    background: $bubble-color;
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
    position: relative;
}

ul {
    list-style-type: none;
    padding: 0;
    width: auto;
    margin-bottom: 2rem;
}

li {
    margin-top: 1rem;
    background: $bubble-two-color;
    width: auto;
    padding: 1rem;
    border-radius: 10px;
}

.btn-twitchFollowers {
    border: none;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: $bubble-two-color;
    margin-right: .5rem;
    color: $color-blue;
    position: absolute;
    bottom: 0;
    width: -webkit-fill-available ;
    margin: 0 1rem 1rem 0;
}

.btn-twitchFollowers:hover {
    background-color: $bubble-color;
    color: $color-light_blue;
}
</style>