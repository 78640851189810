<template>
    <div class="home-btns">
        <router-link :to="{ name: 'TimestampPage' }">
            <button class="btn timestamp">TIMESTAMP</button>
        </router-link>
        <router-link :to="{ name: 'Backoffice' }">
            <button class="btn backoffice">BACKOFFICE</button>
        </router-link>
    </div>
</template>
<script>
export default {
    name: 'HomePage',

}
</script>
<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.home-btns {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    border: none;
    color: $text-color;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-right: .5rem;
    background: $bubble-color;
}

.timestamp {
    background-color: $bubble-two-color;
    color: $text-color;
}

.timestamp:hover {
    background-color: $bubble-two-color_light;
    color: $text-color_light;
}

.backoffice {
    background-color: $bubble-two-color;
    color: $color-red;
}

.backoffice:hover {
    background-color: $bubble-two-color_light;
    color: $color-light_red;
}
</style>
