<template>
    <div class="counterPage">
        <h2>Counter</h2>
        <CounterPage />
        <div class="counter-btn-container">
            <button @click="startCounter" class="counter-btn btn-start">Start</button>
            <button @click="stopCounter" class="counter-btn btn-stop">Stop</button>
            <button @click="resetCounter" class="counter-btn btn-reset">Reset</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CounterPage from './CounterPage.vue'

export default {
    components: {
        CounterPage,
    },
    data() {
        return {
        }
    },
    methods: {
        async startCounter() {
            try {
                await axios.post(`${process.env.VUE_APP_BACKEND_URL}/counter/start`)
            } catch (error) {
                console.log(error)
            }
        },
        async stopCounter() {
            try {
                await axios.post(`${process.env.VUE_APP_BACKEND_URL}/counter/stop`)
            } catch (error) {
                console.log(error)
            }
        },
        async resetCounter() {
            try {
                await axios.post(`${process.env.VUE_APP_BACKEND_URL}/counter/reset`)
            } catch (error) {
                console.log(error)
            }
        },
    },
    computed: {
        counter() {
            return this.$store.status.counter.counter
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.counterPage {
    display: flex;
    flex-direction: column;
    background: $bubble-color;
    width: auto;
    padding: 1rem;
    border-radius: 10px;
    position: relative;
}

.counter-btn-container {
    bottom: 0;
    width: 100%;
    display: flex;
    gap: 1rem;
    margin: auto 0 0;
    padding: 0 2rem 0 0;
}

.counter-btn {
    border: none;
    color: $text-color;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background: $bubble-two-color;
    width: 100%;
}

.btn-start {
    color: $color-green;
}

.btn-start:hover {
    background-color: $bubble-color;
    color: $color-light_green;
}

.btn-stop {
    color: $color-red;
}

.btn-stop:hover {
    background-color: $bubble-color;
    color: $color-light_red;
}

.btn-reset {
    color: $color-yellow;
}

.btn-reset:hover {
    background-color: $bubble-color;
    color: $color-light_yellow;
}
</style>
