import { socket } from "@/socket.js";

const counter = {
    namespaced: true,

    state: {
        counter: 0,
    },
    getters: {},

    mutations: {
        set(state, counter) {
            state.counter = counter;
        },
    },

    actions: {
        async listen({ commit }) {
            socket.on("counter", (counter) => {
                commit('set', counter)
            });
        },
    },
}

export default counter;
