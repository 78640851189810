import axios from 'axios';
import { socket } from "@/socket.js";

const settings = {
    namespaced: true,

    state: {
        all: [],
        fetchedSettings: {},
    },

    getters: {
        name: (state) => (name) => {
            return state.all.find((setting) => setting.name == name)
        },
    },

    mutations: {
        add(state, setting) {
            const index = state.all.findIndex(({ name }) => name == setting.name)
            if (index < 0) {
                state.all.push(setting)
            } else {
                state.all.splice(index, 1, setting)
            }
            state.fetchedSettings[setting.name] = true
        },

        // addTopics(state, topics) {
        //     for (let topic of topics) {
        //         convertTopic(topic)
        //     }
        //     state.all = topics;
        // },

        // delete(state, id) {
        //     const index = state.all.findIndex(topic => id == topic.id)
        //     if (index < 0) {
        //         return false
        //     }
        //     state.all.splice(index, 1)
        //     return true
        // },

        // update(state, topic) {
        //     convertTopic(topic)
        //     const index = this.topics.findIndex(({ id }) => id == topic.id)
        //     if (index < 0) {
        //         state.all.push(topic)
        //     } else {
        //         state.all.splice(index, 1, topic)
        //     }
        // }
    },

    actions: {
        // async listenCreated({ commit }) {
        //     socket.on("topic-created", (topic) => {
        //         commit('add', topic)
        //     });
        // },

        // async listenDeleted({ commit }) {
        //     socket.on("topic-deleted", (id) => {
        //         commit('delete', id)
        //     });
        // },

        async fetch({ commit, state }, setting) {
            if (state.fetchedSettings[setting]) {
                return
            }
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/settings`, {
                    params: {
                        setting
                    }
                });
                commit('add', response.data)
            } catch (error) {
                console.log(error)
            }
        },

        async listenUpdated({ commit }) {
            socket.on("setting-updated", (setting) => {
                commit('add', setting)
            });
        },

        // async fetchAll({ commit }) {
        //     try {
        //         const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/topics`);
        //         commit('addTopics', response.data)
        //     } catch (error) {
        //         console.log(error)
        //     }
        // },
    },
}

export default settings;
