<template>
    <div class="card">
        <h2 v-text="title" v-if="title" class="title"></h2>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        title: { required: false }
    }
}
</script>

<style lang="scss" scoped>
.card {
    background-color: rgba(white, .075);
    border-radius: 1rem;
    overlay: auto;
    padding: 2rem;

    .title {
        font-size: 2rem;
        margin: 0 0 1em;
        line-height: 1;
    }
}
</style>
