<template>
    <div class="topic" :class="state" :style="{ '--progress': `${progress * 100}%` }">
        <span v-text="index" class="index">
        </span>
        <span class="title">{{ topic.topic }}
        </span>
        <span class="timer">{{ minutes }}:{{ seconds }}</span>
    </div>
</template>

<script>
import moment from "moment"

export default {
    name: 'TopicComponent',
    props: {
        topic: { required: true },
    },
    data() {
        return {
        }
    },
    methods: {
        doubleDigit(number) {
            let nr = new String(number)
            if (nr.length == 1) {
                return '0' + nr
            }
            return nr
        },
    },
    computed: {
        index() {
            return this.topics.findIndex(({ id }) => id === this.topic.id) + 1
        },
        state() {
            if (this.progress == 0) {
                return 'upcoming'
            }
            if (this.progress == 1) {
                return 'past'
            }
            return 'inprogress'
        },
        counter() {
            return this.$store.state.counter.counter
        },
        progress() {
            return (1 - this.timeDiff.valueOf() / this.topicDur)
        },
        topics() {
            return this.$store.state.topics.all
        },
        topicDur() {
            return (this.topic.minutes * 60 + this.topic.seconds) * 1000
        },
        timeDiff() {
            let duration = - this.counter
            for (let topic of this.topics) {
                duration = (topic.minutes * 60 + topic.seconds) * 1000 + duration
                if (this.topic == topic) {
                    break
                }
            }
            if (duration < 0) {
                duration = 0
            }
            if (duration > this.topicDur) {
                duration = this.topicDur
            }
            return moment.duration(duration)
        },
        minutes() {
            return this.doubleDigit(this.timeDiff.minutes())
        },
        seconds() {
            return this.doubleDigit(this.timeDiff.seconds())
        },
    },
    watch: {
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.topic {
    display: flex;
    align-items: center;
    justify-content: left;
    border-radius: var(--unit);
    width: 100%;
    background: rgb(51, 52, 57);
    position: relative;
    overflow: hidden;
    gap: calc(var(--unit) * 3);
    padding: calc(var(--unit) * .75) calc(var(--unit) * 2.25);
    line-height: 1;

    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: var(--progress);
        content: '';
        z-index: 1;
        background: rgb(39, 38, 44);
    }

    &.past {
        >* {
            opacity: .1;
        }
    }

    &.upcoming {
        >* {
            opacity: .5;
        }
    }

    >* {
        position: relative;
        z-index: 2;
    }

    font-size: calc(var(--unit) * 2.25);

    .index {
        color: $text-color_light;
    }

    .title {
        color: $text-color_light;
    }

    .timer {
        color: $text-color;
        color: $text-color_light;
        margin: 0 0 0 auto;
    }
}
</style>
