<template>
    <div>
        <img :src="`${LOGO}/logo`" class="logo" alt="logo">
    </div>
</template>

<script>
import { socket } from '@/socket';
const LOGO = process.env.VUE_APP_BACKEND_URL

export default {
    name: 'LogoComponent',
    data() {
        return {
            LOGO,
            hideLogo: false,
        }
    },
    mounted() {
        socket.on("logo-inserted", (inserted) => {
            if (!inserted) {
                return
            }
            this.hideLogo = true
            this.$nextTick().then(() => this.hideLogo = false)
        });
    },
    unmounted() {
        socket.off("logo-inserted")
    },
}
</script>

<style lang="scss" scope>
.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    min-height: 0;
    min-width: 0;
}
</style>
