import axios from 'axios';
import { socket } from "@/socket.js";

function convertTopic(topic) {
    topic.totalMillis = (topic.minutes * 60 + topic.seconds) * 1000
    return topic
}

const messages = {
    namespaced: true,

    state: {
        all: [],
    },

    getters: {},

    mutations: {
        add(state, topic) {
            convertTopic(topic)
            state.all.push(topic)
        },

        addTopics(state, topics) {
            for (let topic of topics) {
                convertTopic(topic)
            }
            state.all = topics;
        },

        delete(state, id) {
            const index = state.all.findIndex(topic => id == topic.id)
            if (index < 0) {
                return false
            }
            state.all.splice(index, 1)
            return true
        },

        update(state, topic) {
            convertTopic(topic)
            const index = this.topics.findIndex(({ id }) => id == topic.id)
            if (index < 0) {
                state.all.push(topic)
            } else {
                state.all.splice(index, 1, topic)
            }
        }
    },

    actions: {
        async listenCreated({ commit }) {
            socket.on("topic-created", (topic) => {
                commit('add', topic)
            });
        },

        async listenDeleted({ commit }) {
            socket.on("topic-deleted", (id) => {
                commit('delete', id)
            });
        },

        async listenUpdated({ commit }) {
            socket.on("topic-updated", (topic) => {
                commit('update', topic)
            });
        },

        async fetchAll({ commit }) {
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/topics`);
                commit('addTopics', response.data)
            } catch (error) {
                console.log(error)
            }
        },
    },
}

export default messages;

// socket.off("topic-created")
// socket.off("topic-updated")
// socket.off("topic-deleted")
