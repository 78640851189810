<template>
    <div class="topics-component">
        <TopicComponent v-for="topic in topics" :topic="topic" :key="topic.id" class="topics-container">
        </TopicComponent>
    </div>
</template>

<script>
import TopicComponent from "@/components/TopicComponent.vue"

export default {
    name: 'TopicsComponent',
    components: {
        TopicComponent,
    },
    props: {
        counter: { required: true },
    },
    data() {
        return {
        }
    },
    computed: {
        topics() {
            return this.$store.state.topics.all
        }
    },
    mounted() {
        this.$store.dispatch("topics/fetchAll")
    },
}
</script>

<style lang="scss" scoped>
.topics-component {
    display: flex;
    flex-direction: column;
    gap: calc(var(--unit) * .2);
}
</style>
