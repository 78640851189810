<template>
    <div class="backofficeSettings">
        <CardPanel title="Settings" class="settings">
            <ul>
                <li @click.prevent="toggleOrientationSetting">
                    <span class="">Vertical</span>
                    <label class="switch">
                        <input type="checkbox" :checked="orientationValue == 'vertical'">
                        <span class="slider round"></span>
                    </label>
                </li>
            </ul>
        </CardPanel>
    </div>
</template>

<script>
import CardPanel from '@/components/CardPanel'
import axios from "axios"

export default {
    name: 'BackofficeSettings',
    components: {
        CardPanel,
    },
    methods: {
        toggleOrientationSetting() {
            let value = 'normal'
            if (this.orientationValue == 'normal') {
                value = 'vertical'
            }
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/settings`, {
                name: 'orientation', value
            });
        },
    },
    computed: {
        orientationSetting() {
            return this.$store.getters['settings/name']('orientation')
        },
        orientationValue() {
            if (this.orientationSetting == null) {
                return 'normal'
            }
            return this.orientationSetting.value
        },
    },
    mounted() {
        this.$store.dispatch('settings/fetch', 'orientation')
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables";

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked+.slider {
            background-color: #2196F3;
        }

        &:focus+.slider {
            box-shadow: 0 0 1px #2196F3;
        }

        &:checked+.slider:before {
            transform: translateX(26px);
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;

        &.round {
            border-radius: 34px;

            &:before {
                border-radius: 50%;
            }
        }

        &:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            transition: .4s;
        }
    }
}

.backofficeSettings {
    padding: 2rem;

    .settings {
        width: fit-content;
        margin: auto;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 400px;
        }
    }
}
</style>
