<template>
    <div class="totalProgress" :style="{ '--progress': `${progress * 100}%` }">
        <ul>
            <li v-for="topic in topics" :key="topic.id" :style="{ '--duration': topic.totalMillis }"></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'TotalProgress',
    computed: {
        counter() {
            return this.$store.state.counter.counter
        },
        progress() {
            return this.counter / this.totalTime
        },
        topics() {
            return this.$store.state.topics.all
        },
        totalTime() {
            return this.$store.getters['totalTime']
        },
    },
}
</script>

<style lang="scss" scoped>
.totalProgress {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    &::after {
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        content: '';
        background-color: red;
        height: calc(var(--unit) * .3);
        margin: auto auto auto 0;
        border-radius: var(--unit);
        width: var(--progress);
        max-width: 100%;
    }

    ul {
        display: flex;
        width: 100%;
        height: calc(var(--unit) * .3);
        margin: auto;
        gap: var(--unit);
        list-style: none;
        padding: 0;

        li {
            flex-basis: 0;
            flex-grow: var(--duration);
            background-color: rgba(white, .2);
            border-radius: var(--unit);
        }
    }
}
</style>
