<template>
    <div class="logo-upload">
        <h2>Change Logo</h2>
        <br>
        <input class="logo-input" type="file" id="file-upload" name="image" @change="handleLogoUpload" ref="fileInput">
        <label for="file-upload" class="custom-file-upload">{{ selectedFile }}</label>
        <br>
        <br>
        <p>Logo Chosen</p>
        <div class="logo-container">
            <img v-if="!hideLogo" :src="LOGO" class="logo">
        </div>
        <p class="errorMessage" v-text="chooseLogo"></p>
        <button class="logo-btn" @click="uploadLogo">Submit</button>
    </div>
</template>
<script>
import axios from 'axios'
import { socket } from '@/socket';

export default {
    name: 'UploadLogo',
    data() {
        return {
            file: null,
            chooseLogo: null,
            imageUploaded: null,
            selectedFile: 'Choose Your Logo',
            hideLogo: false,
            LOGO: `${process.env.VUE_APP_BACKEND_URL}/logo`,
        }
    },
    methods: {
        handleLogoUpload(event) {
            this.file = event.target.files[0]
            if (this.file) {
                this.selectedFile = this.file.name;
            }
        },
        async uploadLogo() {
            if (!this.file) {
                this.chooseLogo = "Please select logo"
            }

            const logo = new FormData()
            logo.append('image', this.file)

            try {
                await axios.post(`${process.env.VUE_APP_BACKEND_URL}/uploadLogo`, logo)
                this.$emit('logo-inserted', logo);
                this.$refs.fileInput.value = '';
                this.selectedFile = 'Choose Your Logo'
                this.chooseLogo = ''
            } catch (error) {
                console.log(error)
            }
        }
    },
    mounted() {
        socket.on("logo-inserted", (inserted) => {
            if (!inserted) {
                return
            }
            this.hideLogo = true
            this.$nextTick().then(() => this.hideLogo = false)
        });
    },
    unmounted() {
        socket.off("logo-inserted")
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.errorMessage {
    color: $color-red;
    margin: 1rem 0;
}

.logo-upload {
    background: $bubble-color;
    padding: 1rem;
    border-radius: 10px;
    width: auto;
}

.custom-file-upload {
    border: 1px solid $bubble-two-color;
    background-color: $bubble-two-color;
    color: $text-color;
    padding: .5rem;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: inline-block;
}

.custom-file-upload:hover {
    color: $text-color_light;
    background-color: $color-blue;
}

.logo-input {
    display: none;
    color: $text-color;
}


.logo-input:focus {
    border-color: $color-blue;
    outline: 0;
    box-shadow: 0 0 0 0.2rem $color-blue;
}

.logo-btn {
    border: none;
    color: $color-blue;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: $bubble-two-color;
    margin-right: .5rem;
    width: 100%;
}

.logo-btn:hover {
    color: $color-light_blue;
    background: $bubble-color;
}

.logo-container {
    border: 1px solid $bubble-two-color;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
}

.logo-upload {
    // margin-bottom: 1rem;
    background: $bubble-color;
    padding: 1rem;
    border-radius: 10px;
    width: auto;
}

.custom-file-upload {
    border: 1px solid $bubble-two-color;
    background-color: $bubble-two-color;
    color: $text-color;
    padding: .5rem;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    display: inline-block;
}

.custom-file-upload:hover {
    color: $text-color_light;
    background-color: $color-blue;
}

.logo-input {
    display: none;
    color: $text-color;
}


.logo-input:focus {
    border-color: $color-blue;
    outline: 0;
    box-shadow: 0 0 0 0.2rem $color-blue;
}

.logo-btn {
    border: none;
    color: $color-blue;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: $bubble-two-color;
    margin-right: .5rem;
    width: 100%;
}

.logo-btn:hover {
    color: $color-light_blue;
    background: $bubble-color;
}

.logo-container {
    border: 1px solid $bubble-two-color;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
}

.logo {
    width: 50%;
    padding: 1rem;
}
</style>
