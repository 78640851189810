<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: 'App',
    mounted() {
        this.$store.dispatch("counter/listen")

        this.$store.dispatch("messages/listenInserted")
        this.$store.dispatch("messages/listenRemoved")

        this.$store.dispatch("topics/listenCreated")
        this.$store.dispatch("topics/listenDeleted")
        this.$store.dispatch("topics/listenUpdated")

        this.$store.dispatch("settings/listenUpdated")
    }
}
</script>

<style lang="scss">
@import "modern-normalize";
@import "@/assets/style.scss";
@import "@/assets/_variables.scss";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body,
#app {
    min-height: 100vh;
    background: rgba($background, .5);
    max-width: 100%;
}

#app {
    display: flex;
}
</style>
